// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-event-post-jsx": () => import("/opt/build/repo/src/templates/EventPost.jsx" /* webpackChunkName: "component---src-templates-event-post-jsx" */),
  "component---src-templates-news-post-jsx": () => import("/opt/build/repo/src/templates/NewsPost.jsx" /* webpackChunkName: "component---src-templates-news-post-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-email-signup-jsx": () => import("/opt/build/repo/src/pages/email-signup.jsx" /* webpackChunkName: "component---src-pages-email-signup-jsx" */),
  "component---src-pages-events-jsx": () => import("/opt/build/repo/src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-info-boards-jsx": () => import("/opt/build/repo/src/pages/info/boards.jsx" /* webpackChunkName: "component---src-pages-info-boards-jsx" */),
  "component---src-pages-info-index-jsx": () => import("/opt/build/repo/src/pages/info/index.jsx" /* webpackChunkName: "component---src-pages-info-index-jsx" */)
}

